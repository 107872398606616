<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <b-card>
            <b-row>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Client</th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="clientRules">
                <b-form>
                    <b-row class="mt-2 border-bottom pb-1">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="Name">Name</label>
                                <validation-provider name="Name" #default="{ errors }" rules="required">
                                    <b-form-input id="name" type="text" v-model="clientValue.name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Contact Person" label-for="contact_person">
                                <b-form-input id="contact_person" type="text" v-model="clientValue.contact_person"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <!-- Email -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Email" label-for="email">
                                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                                    <b-form-input id="email" type="text" v-model="clientValue.email"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3" v-if="clientValue.id == null">
                            <b-form-group label="Password" label-for="password">
                                <validation-provider name="Password" #default="{ errors }" rules="required">
                                    <b-form-input id="password" type="text" v-model="clientValue.password"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3" v-else>
                            <b-form-group label="Password" label-for="password">
                                <b-form-input id="password" type="text" v-model="clientValue.password"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <!-- Password -->
                        <!-- v-if="userId == null" -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Phone" label-for="phone">
                                <!-- <b-form-input id="phone" type="tel" v-model="clientValue.phone"></b-form-input> -->
                                <cleave id="phone" v-model="clientValue.phone" class="form-control" :raw="false" :options="$func.getInputOptions().phone" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Address" label-for="address">
                                <b-form-input id="address" type="text" v-model="clientValue.address1"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="City" label-for="city">
                                <b-form-input id="city" type="text" v-model="clientValue.city"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="VAT Number" label-for="vat_number">
                                <b-form-input id="vat_number" type="text" v-model="clientValue.vat_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <!-- <b-row class="mt-2 border-bottom pb-1">
                        <b-col class="mb-1" cols="12"><strong> Foreman Rates</strong></b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate UK" label-for="f_day_rate">
                                <cleave id="f_day_rate" v-model="clientValue.f_day_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate UK" label-for="f_weekend_rate">
                                <cleave id="f_weekend_rate" v-model="clientValue.f_weekend_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate EU" label-for="f_day_rate">
                                <cleave id="f_day_rate" v-model="clientValue.f_day_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate EU" label-for="f_weekend_rate_eu">
                                <cleave id="f_weekend_rate_eu" v-model="clientValue.f_weekend_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate Global" label-for="f_day_rate">
                                <cleave id="f_day_rate" v-model="clientValue.f_day_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate  Global" label-for="f_weekend_rate_glo">
                                <cleave id="f_weekend_rate_glo" v-model="clientValue.f_weekend_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Overtime (per hour)" label-for="f_overtime">
                                <cleave id="f_overtime" v-model="clientValue.f_overtime" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Lodging Allowance" label-for="f_lodging">
                                <cleave id="f_lodging" v-model="clientValue.f_lodging" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2 border-bottom pb-1">
                        <b-col class="mb-1" cols="12"><strong> Tent Builder Rates</strong></b-col>
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate UK" label-for="day_rate">
                                <cleave id="day_rate" v-model="clientValue.day_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate UK" label-for="weekend_rate">
                                <cleave id="weekend_rate" v-model="clientValue.weekend_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate EU" label-for="day_rate_eu">
                                <cleave id="day_rate_eu" v-model="clientValue.day_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate EU" label-for="weekend_rate_eu">
                                <cleave id="weekend_rate_eu" v-model="clientValue.weekend_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Day Rate  Global" label-for="day_rate_glo">
                                <cleave id="day_rate_glo" v-model="clientValue.day_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Weekend Rate  Global" label-for="weekend_rate_glo">
                                <cleave id="weekend_rate_glo" v-model="clientValue.weekend_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Overtime (per hour)" label-for="overtime">
                                <cleave id="overtime" v-model="clientValue.overtime" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Lodging Allowance" label-for="Lodging Allowance">
                                <cleave id="day_rate" v-model="clientValue.lodging" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>
                    </b-row> -->

                    <b-row class="mt-2">
                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Select Text Colour" label-for="gradeLeves">
                                <validation-provider #default="{ errors }" name="chartColour">
                                    <verte display="widget" :showHistory="null" v-model="clientValue.textColour" model="hex"></verte>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Select Background Colour" label-for="gradeLeves">
                                <validation-provider #default="{ errors }" name="chartColour">
                                    <verte display="widget" :showHistory="null" v-model="clientValue.colour" model="hex"></verte>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group label="Preview" label-for="gradeLeves">
                                <template>
                                    <b-badge :style="{ 'background-color': clientValue.colour, color: clientValue.textColour }" class="text-capitalize p-1">
                                        <span>{{ clientValue.name ? clientValue.name : "Client Name" }}</span>
                                    </b-badge>
                                </template>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-button v-if="clientId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="clientValue.status != 'deleted'" variant="primary" class="mr-1 float-right"> Save Changes </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import Verte from "verte";
import "verte/dist/verte.css";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,

        vSelect,
        Cleave,
        ToastificationContent,
        Verte,
        ValidationProvider,
        ValidationObserver,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const CLIENT_APP_STORE_MODULE_NAME = "client";

        // Register module
        if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var clientValue = {
            name: null,
            contact_person: null,
            email: null,
            phone: null,
            address1: null,
            city: null,
            vat_number: null,
            colour: "#000000",
            textColour: "#ffffff",
            password: null,
            day_rate_eu: null,
            day_rate: null,
            weekend_rate: null,
            weekend_rate_eu: null,
            day_rate_glo: null,
            weekend_rate_glo: null,
            overtime: null,
            f_day_rate_eu: null,
            f_day_rate: null,
            f_weekend_rate: null,
            f_weekend_rate_eu: null,
            f_day_rate_glo: null,
            f_weekend_rate_glo: null,
            overtime: null,
        };

        const statusOptions = [
            { label: "Active", value: "active" },
            { label: "Passive", value: "passive" },
            { label: "Delete", value: "deleted" },
        ];

        return {
            clientValue: clientValue,

            baseURL: store.state.app.baseURL,
            required,
            alphaNum,
            email,

            roleOptions: [],
            statusOptions,
            clientId: null,
            formShow: false,
            permissions: [],

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
        };
    },

    created() {
        this.getClientById();
    },

    methods: {
        getClientById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.clientId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("client/fetchClient", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.clientValue = response.data;
                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        formSubmitted() {
            this.formShow = true;

            this.$refs.clientRules.validate().then((success) => {
                if (success) {
                    if (router.currentRoute.params.id) {
                        store
                            .dispatch("client/updateClient", this.clientValue)
                            .then((response) => {
                                if (response.status == 200) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Client Update Successful",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    this.formShow = false;
                                    router.push({ name: "client-view", params: { id: response.data.id } });
                                }
                            })
                            .catch((e) => {
                                if (e.response.status == 406) {
                                    this.$swal({
                                        title: "Error",
                                        text: "E-mail address must be unique.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                } else {
                                    let error = e.status.code;
                                    let message = "Process Failed with Error Code:";
                                    let error_message = message.concat(" ", error);

                                    this.$swal({
                                        title: "Error",
                                        text: error_message,
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                }

                                this.formShow = false;
                            });
                    } else {
                        store
                            .dispatch("client/addClient", this.clientValue)
                            .then((response) => {
                                if (response.status == 200) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Client Add Successful",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    setTimeout(function() {
                                        this.formShow = false;
                                        router.push({ name: "client-view", params: { id: response.data.id } });
                                    }, 500);
                                }
                            })
                            .catch((e) => {
                                if (e.response.status == 406) {
                                    this.$swal({
                                        title: "Error",
                                        text: "E-mail address must be unique.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                } else {
                                    let error = e.status.code;
                                    let message = "Process Failed with Error Code:";
                                    let error_message = message.concat(" ", error);

                                    this.$swal({
                                        title: "Error",
                                        text: error_message,
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                }

                                this.formShow = false;
                            });
                    }
                } else {
                    this.formShow = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.verte__controller {
    display: none;
}
</style>
